/* eslint-disable react/jsx-key */
import React from 'react';
import logo from 'src/assets/images/logo.png';
import phone from 'src/assets/icons/phone.svg';
import mail from 'src/assets/icons/mail.svg';

import { Contact } from 'src/components/atoms/contact';

import { LinkAppMobile } from 'src/components/molecules/link-app-mobile';

import PATHES from 'src/constants/pathes';
import { useTranslation } from 'react-i18next';

export interface FooterProps {
  children?: React.ReactNode;
}

export const Footer: React.FC<FooterProps> = () => {
  const { t } = useTranslation();
  return (
    <footer className="o-footer">
      <div className="container o-footer__content">
        <div className="o-footer__info">
          <div className="o-footer__contact">
            <div className="o-footer__contact--logo">
              <LinkAppMobile href={PATHES['HOME_PAGE']} src={logo} />
            </div>
            <div className="o-footer__contact--link">
              <Contact name={phone} href="tel:0877365366">
                {t('login.phone')}: (+84) 877.365.366
              </Contact>
              <Contact name={mail} href="mailto:royalhealthcare.asia@gmail.com">
                Email: royalhealthcare.asia@gmail.com
              </Contact>
            </div>
          </div>
          {/* <div className="o-footer__info--content">
          <Heading level="h4">Lorem ipsum dolor sit amet consectetur adipisicing elit. </Heading>
          <Text type="p">Lorem ipsum dolor sit amet consectetur adipisicing elit. </Text>
          <Text type="p">Lorem ipsum dolor sit amet consectetur adipisicing elit.</Text>
        </div> */}
        </div>
        {/* <ul className="o-footer__link">
        <li>
          <TextLink href="/">Lorem ipsum dolor</TextLink>
        </li>
        <li>
          <TextLink href="/">Lorem ipsum dolor</TextLink>
        </li>
        <li>
          <TextLink href="/">Lorem ipsum dolor</TextLink>
        </li>
        <li>
          <TextLink href="/">Lorem ipsum dolor</TextLink>
        </li>
        <li>
          <TextLink href="/">Lorem ipsum dolor</TextLink>
        </li>
        <li>
          <TextLink href="/">Lorem ipsum dolor</TextLink>
        </li>
        <li>
          <TextLink href="/">Lorem ipsum dolor</TextLink>
        </li>
      </ul>
      <div className="o-footer__branch">
        <div className="o-footer__branch--item">
          <Heading level="h4">Lorem ipsum dolor</Heading>
          <Text type="p">Lorem ipsum dolor Lorem ipsum dolor</Text>
        </div>
        <div className="o-footer__branch--item">
          <Heading level="h4">Lorem ipsum dolor</Heading>
          <Text type="p">Lorem ipsum dolor Lorem ipsum dolor</Text>
        </div>
        <div className="o-footer__branch--item">
          <Heading level="h4">Lorem ipsum dolor</Heading>
          <Text type="p">Lorem ipsum dolor Lorem ipsum dolor</Text>
        </div>
      </div> */}
      </div>
    </footer>
  );
};
